import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React, { Suspense, useEffect, useState } from 'react';
import ErrorBoundary from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import UserAccountsContainer from './authenticated/user-accounts/user-accounts.container';
import SideNav from './authenticated/user-accounts/components/side-nav.component';
import ProductionFallbackContainer from './library/components/production-fallback-container';
import DevelopmentFallbackContainer from './library/components/development-fallback-container';
import Loading from './library/components/Loading/loading.component';
import { isDonor as isDonorAction } from './authenticated/user-accounts/user-accounts.actions';
import history from '../history';
import {
  sessionInitialize,
  sessionRefreshOnboarding,
} from './unauthenticated/actions/session-initialize.actions';
import UpgradeModal from './library/components/Upgrade/UpgradeModal';
import { hideModal } from './authenticated/modal/modal.actions';
import Modal from './library/components/modal.component';
import { consoleLog } from './library/functions';

export const AuthenticatedRoute = withAuthenticationRequired(
  ({ component, renderSideNav, path, children, ...routeProps }) => {
    // This is the lazy loaded component that should be rendered on Suspense.
    const [tokenInCache, setTokenInCache] = useState(
      !!localStorage.getItem('id_token')
    );
    const Component = component;
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const {
      open: modalOpen,
      text: modalText,
      onAgree: modalOnAgree,
      export: modalExport,
      noCancel: modalNoCancel,
    } = useSelector(state => state.modalReducer);
    const { selectedAccount, isDonor } = useSelector(
      state => state.accountReducer
    );
    const dispatch = useDispatch();
    const [sendHubspotAnalytics, setSendHubspotAnalytics] = useState(false);
    const { location: pathname } = useLocation();
    const [isStateLoading, setIsStateLoading] = useState(true);
    useEffect(() => {
      const setTokenToLocalStorage = async () => {
        // TODO: Ideally we wouldn't store the idToken in local storage
        const auth0Token = await getAccessTokenSilently({
          audience: process.env.AUTH0_AUDIENCE,
        });
        localStorage.setItem('id_token', auth0Token);
        console.log(
          `The ID Token has been set up in the local Storage. We know that the user has been logged in and we will be using the ID token ${auth0Token} in the requests to the server as authentication`
        );
        // The following line is only used for testing purposes. Should be removed when the
        // Redux library has been migrated to redux-toolkit and the tests are using only msw server
        // instead of pre-built states.
        setTokenInCache(true);
      };
      if (isAuthenticated && !localStorage.getItem('id_token')) {
        setTokenToLocalStorage();
      }
    }, [isAuthenticated]);

    useEffect(() => {
      // For logging the pathname changes
      // consoleLog('-> AppContainer | Change Route:', { lastRoute }, { pathname });
    }, pathname);

    useEffect(() => {
      const isDonorRoute = path === '/my_profile';
      if (isDonorRoute && isDonor === 'false') {
        dispatch(isDonorAction('true'));
      } else if (!isDonorRoute && isDonor === 'true' && selectedAccount) {
        dispatch(isDonorAction('false'));
      }
    }, [path]);

    useEffect(() => {
      const fetchSelectedAccount = async () => {
        let retrievedAccount = null;
        if (window.location.pathname === '/onboarding') {
          await dispatch(sessionRefreshOnboarding());
        } else {
          retrievedAccount = await dispatch(sessionInitialize());
          if (
            retrievedAccount === null &&
            localStorage.getItem('isDonor') !== 'true'
          ) {
            console.log(
              'This is an account being onboarded. We need to redirect it forcefully to /onboarding, at least until the account is created.'
            );
            history.replace({ pathname: '/onboarding' });
          }
        }
        setIsStateLoading(false);
      };
      // note: admins should have at least one accountsByUser,
      //       but non-admins don't need any accountsByUser

      // consoleLog(
      //   `UnconnectedAuthenticatedRoute`,
      //   { isAuthenticated },
      //   { isLoading },
      //   { selectedAccount },
      //   { codeArea: 'routes' }
      // );

      // only perform this if the ID Token is already set in local storage
      // this is another reason to remove it altogether from there.
      // It is already stored by the cache management of Auth0
      if (!tokenInCache) {
        return;
      }

      console.log(
        'Given that we have a token in cache now, we can do queries to the backend'
      );

      if (isAuthenticated) {
        console.log(
          `Checking if the user isDonor. If it is, we don't need to get a list of accounts from the backend. isDonor: ${isDonor}`
        );
        if (isDonor === 'true') {
          setIsStateLoading(false);
          return;
        }
        console.log(
          `As the user is NOT a donor, we need to obtain the selected account, in case it doesn't exist. Selected account is obtained from REDUX, not from localStorage. Current selected account value`
        );
        // console.log(selectedAccount);
        if (selectedAccount === null) {
          console.log(
            'The selected account is null. Refetching from the backend...'
          );
          // we need to re-fetch
          fetchSelectedAccount();
        } else {
          console.log('The selected account is not null. Showing the page...');
          setIsStateLoading(false);

          if (typeof CobrowseIO !== 'undefined') {
            window.CobrowseIO.customData = {
              account_id: selectedAccount.id,
              account_subdomain: selectedAccount.subdomain,
            };
          }
        }
      } else if (!isLoading) {
        // user is not authenticated and isn't loading
        console.log(
          'User is not authenticated and Auth0 is not loading. Redirect to login so that user can login...'
        );
        history.push('/login');
      }
    }, [isAuthenticated, selectedAccount, isLoading, isDonor, tokenInCache]);

    useEffect(() => {
      if (!sendHubspotAnalytics) {
        if (window._hsq) {
          window._hsq.push(['setPath', window.location.pathname]);
          setSendHubspotAnalytics(true);
        }
      }
    });

    if (isLoading || isStateLoading || !isAuthenticated) {
      return null;
    }

    if (!tokenInCache) {
      return null;
    }

    return (
      <>
        <Route
          path={path}
          {...routeProps}
          render={() => (
            <>
              <div className="home-wrapper">
                <div>
                  <UserAccountsContainer />
                </div>
              </div>
              {renderSideNav && <SideNav />}
              <ErrorBoundary
                FallbackComponent={
                  process.env.NODE_ENV === 'production'
                    ? ProductionFallbackContainer
                    : DevelopmentFallbackContainer
                }
              >
                {/* Render the Component with Suspense if provided; otherwise return the children (original behavior) */}
                {component ? (
                  <Suspense
                    fallback={
                      <div>
                        <div className="h-24 flex items-center w-full">
                          <div className="ml-4 text-3xl text-black pt-6 font-medium" />
                        </div>
                        <div className="page-content inline-block pl-6">
                          <Loading />
                        </div>
                      </div>
                    }
                  >
                    <Component />
                  </Suspense>
                ) : (
                  children
                )}
              </ErrorBoundary>
            </>
          )}
        />
        {modalOpen && (
          <Modal
            noCancel={modalNoCancel}
            modalText={modalText}
            closeFunction={() => dispatch(hideModal())}
            agreeFunction={agreeArgs => {
              if (modalOnAgree) {
                modalOnAgree(agreeArgs);
              }
              dispatch(hideModal());
            }}
            export={modalExport}
          />
        )}
        <UpgradeModal />
      </>
    );
  }
);

export const UnauthenticatedRoute = ({
  children,
  component,
  ...routeProps
}) => {
  const Component = component;
  const [sendHubspotAnalytics, setSendHubspotAnalytics] = useState(false);

  useEffect(() => {
    // only init() in the UnAuthenticatedRoute - we don't need to track inside the Dashboard.
    if (window.DonatelyMarketing) {
      let config = {};
      let publicPaths = null;
      if (
        typeof process === 'object' &&
        process.env.NODE_ENV !== 'production'
      ) {
        publicPaths = new URL(process.env.REACT_APP_PUBLIC_URL);
        config = { config: { 'domain-base': publicPaths.hostname } };
      }
      window.DonatelyMarketing.init(config);
    }

    if (!sendHubspotAnalytics) {
      if (window._hsq) {
        window._hsq.push(['setPath', window.location.pathname]);
        // consoleLog(`HubspotAnalytics | setPath: ${window.location.pathname}`, {codeArea: 'routes'});
        setSendHubspotAnalytics(true);
      }
    }
  }, []);

  return (
    <Route
      {...routeProps}
      render={() => {
        // Render the Component with Suspense if provided; otherwise return the children (original behavior)
        if (component) {
          return (
            <Suspense fallback={<div>loading...</div>}>
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Suspense>
          );
        }

        return <ErrorBoundary>{children}</ErrorBoundary>;
      }}
    />
  );
};
