import { get } from 'lodash';
import { fetchUserSettings } from '../../authenticated/my-profile/my-profile.actions';
import {
  fetchAccounts,
  isDonor as isDonorAction,
  pushToCRM,
  setUser,
  updateLastLogin,
} from '../../authenticated/user-accounts/user-accounts.actions';
import { HEADERS } from '../../library/constants';
import { rebuildApiHeaders, consoleLog } from '../../library/functions';

export const checkDonorStatus = (user, selectedAccount) => (
  dispatch,
  getState
) =>
  new Promise(async resolve => {
    const state = getState();
    let donations = get(
      state,
      `donationReducer.donationsByAccount.${selectedAccount?.id}.items`,
      []
    );

    if (donations.length === 0) {
      const donationsResponse = await fetch(
        `${process.env.DONATELY_API_V2_URL}/donations/mine.json?limit=1`,
        {
          method: 'GET',
          json: true,
          rejectUnauthorized: false,
          headers: rebuildApiHeaders(user),
        }
      );
      if (donationsResponse.status !== 200) {
        console.error(
          `Error obtaining donations response ${donationsResponse}`
        );
        return false;
      }
      await donationsResponse.json().then(({ data }) => {
        donations = data;
      });
    }
    if (!localStorage.getItem('isDonor')) {
      dispatch(
        isDonorAction(
          !selectedAccount && donations.length > 0 ? 'true' : 'false'
        )
      );
    } else {
      dispatch(
        isDonorAction(JSON.parse(localStorage.getItem('isDonor')).toString())
      );
    }

    resolve();
  });

export const checkFundraiserStatus = user => () =>
  new Promise(async resolve => {
    const fundraisersResponse = await fetch(
      `${process.env.DONATELY_API_V2_URL}/fundraisers/mine.json?limit=1`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(user),
      }
    );
    if (fundraisersResponse.status !== 200) {
      console.error(
        `Error obtaining fundraisers response ${fundraisersResponse}`
      );
      resolve();
    } else {
      const fundraisers = await fundraisersResponse.json();
      localStorage.setItem(
        'hasFundraisers',
        JSON.stringify(fundraisers.data.length > 0)
      );
      resolve(fundraisers.data);
    }
  });

function getSessionData(hasBeenInitialized) {
  return async dispatch => {
    const authHeaders = Object.assign(HEADERS, {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    });
    await dispatch(setUser(authHeaders));
    await dispatch(fetchUserSettings(authHeaders));
    const selectedAccount = await dispatch(fetchAccounts(authHeaders));
    await dispatch(checkDonorStatus(authHeaders, selectedAccount));
    await dispatch(checkFundraiserStatus(authHeaders, selectedAccount));

    if (!localStorage.getItem('lastLoginSent') && !hasBeenInitialized) {
      dispatch(updateLastLogin(authHeaders));
    }
    return selectedAccount;

    /*
      let's not push to CRM for now, because were doing it from the API as well.
      2024/08/20
     */
    // if (
    //   !localStorage.getItem('pushToCRM') &&
    //   authHeaders &&
    //   selectedAccount &&
    //   !hasBeenInitialized
    // ) {
    //   dispatch(pushToCRM(authHeaders, selectedAccount));
    // }

    // consoleLog(
    //   'sessionInitialize & redirect',
    //   { isAdmin },
    //   { isDonor },
    //   { isFundraiser },
    //   { stripeConnectId },
    //   { partnerType }
    // );
  };
}

export function sessionInitialize() {
  return getSessionData(false);
}

export function sessionRefresh() {
  return getSessionData(true);
}

export function sessionRefreshOnboarding() {
  return async dispatch => {
    const authHeaders = Object.assign(HEADERS, {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    });
    await dispatch(setUser(authHeaders));
    const result = dispatch(fetchUserSettings(authHeaders));
    await dispatch(fetchAccounts(authHeaders));
    return result;
  };
}
