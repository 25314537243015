import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import { PRICING_PLANS } from '../../../library/constants';
import { isRestrictedIfMatchesSubscription } from '../../../library/functions';
import useClickOutside from '../../../library/hooks/useClickOutside';
import {
  selectedAccount as selectedAccountAction,
  isDonor as isDonorAction,
} from '../user-accounts.actions';
import ContactSupport from './nav-sections/contact-support';

const TopNavMobile = () => {
  const hamburgerMenuRef = useRef(null);
  const accountSelectorRef = useRef(null);
  const { storeUser, accountsByUser, selectedAccount, isDonor } = useSelector(
    state => state.accountReducer
  );
  const { items: accounts } = accountsByUser[storeUser] || {
    items: [],
  };
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileAccountsOpen, setMobileAccountsOpen] = useState(false);
  const dispatch = useDispatch();
  const toggleMobileNav = () => {
    if (mobileOpen) {
      setMobileAccountsOpen(false);
    }
    setMobileOpen(!mobileOpen);
  };
  const toggleMobileAccounts = () => {
    setMobileAccountsOpen(!mobileAccountsOpen);
  };
  const goToProfile = () => {
    localStorage.setItem('isDonor', 'true');
    dispatch(isDonorAction('true'));
    document
      .getElementById('changing-header')
      .classList.add('donor-background');
    document
      .getElementById('changing-header')
      .classList.remove('topnav-blue-background');
  };
  const goToAccountDashboard = () => {
    localStorage.setItem('isDonor', 'false');
    dispatch(isDonorAction('false'));
    document
      .getElementById('changing-header')
      .classList.add('topnav-blue-background');
    document
      .getElementById('changing-header')
      .classList.remove('donor-background');
  };
  useClickOutside({
    ref: hamburgerMenuRef,
    callback: toggleMobileNav,
  });
  useClickOutside({
    ref: accountSelectorRef,
    callback: () => {
      setMobileAccountsOpen(false);
    },
  });

  // TODO: understand why is this here and if it can be moved to useHasAccessTo
  const isRestrictedForFreePlans = isRestrictedIfMatchesSubscription(
    storeUser,
    selectedAccount,
    [PRICING_PLANS.Free, PRICING_PLANS['Discontinued-Free']]
  );

  const goToLogout = () => history.push({ pathname: '/logout' });

  return (
    <div className="navbar my-auto block lg:hidden">
      <div className="dropdown" ref={hamburgerMenuRef}>
        <button
          id="hamburger-nav"
          className="dropbtn"
          onClick={toggleMobileNav}
        >
          <svg
            className="h-6 fill-current mr-2 my-auto ml-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              className="heroicon-ui"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
        {mobileOpen && (
          <div className="mobile-nav-list pin-l pin-r pin-b z-1000 bg-white fixed overflow-y-auto">
            <FontAwesomeIcon
              icon={['far', 'times']}
              className="float-right text-grey-darker mr-4 mt-2"
              onClick={toggleMobileNav}
            />
            {isDonor === 'false' && (
              <ul className="w-full mobile-nav-ul m-auto list-reset pt-8">
                <li className="dropdown-mobile-li border-b border-t border-grey-light mb-4 bg-white">
                  <div
                    className="dropdown-mobile"
                    ref={accountSelectorRef}
                    // TODO add ref here
                    onClick={() => toggleMobileAccounts()}
                  >
                    <button className="dropbtn pr-8 important-text-dark">
                      {selectedAccount?.title}
                      {accounts.length > 1 && (
                        <FontAwesomeIcon
                          icon={['far', 'angle-down']}
                          className="ml-2"
                        />
                      )}
                    </button>
                    {mobileAccountsOpen && (
                      <div className="dropdown-content overflow-y-auto overflow-x-hidden h-48 w-1/2">
                        <ul className="w-full p-0">
                          {accounts.map((account, i) => (
                            <li
                              className="p-3 select-account flex nowrap text-grey-darker hover:text-teal items-center cursor-pointer bg-white hover:bg-grey-lighter"
                              key={i}
                              onClick={() =>
                                dispatch(selectedAccountAction(account))
                              }
                              value={account.unique_identifier}
                            >
                              <div className="border-pale-blue flex-none bg-white bg-contain rounded-full h-12 w-12 flex items-center justify-center">
                                {account.images.logo.original ? (
                                  <img
                                    className="w-8"
                                    src={account.images.logo.original}
                                    alt=""
                                  />
                                ) : (
                                  <svg
                                    className="h-4 fill-current mr-2 my-auto pl-3 inline-block w-8"
                                    viewBox="0 0 24 18.7"
                                  >
                                    <path
                                      d="M23.1,4.7l-3.8-3.8c-1.1-1.1-2.8-1.1-3.8,0L12,4.3L8.6,0.9c-1.1-1.1-2.8-1.1-3.8,0L0.9,4.7
                              c-1.1,1.1-1.1,2.8,0,3.8l9.2,9.2c1.1,1.1,2.8,1.1,3.8,0l2.9-2.9l0,0l6.2-6.2C24.2,7.5,24.2,5.8,23.1,4.7z M16.8,1.5
                              c0.3-0.3,0.8-0.3,1.1,0l0.7,0.7l-4.4,4.4L13,5.3L16.8,1.5z M22.5,7.2L18.7,11l-3.5-3.5l4.4-4.4l3,3C22.8,6.4,22.8,6.9,22.5,7.2z"
                                    />
                                  </svg>
                                )}
                              </div>
                              <div className="ml-3 flex-1">
                                {account.livemode === false
                                  ? `${account.title} (test mode)`
                                  : account.title}
                              </div>
                            </li>
                          ))}
                          <li
                            className="p-3 select-account flex nowrap text-grey-darker items-center cursor-pointer bg-white hover:bg-grey-lighter "
                            onClick={() =>
                              history.push({
                                pathname: '/create_account',
                                state: { newAccount: true },
                              })
                            }
                          >
                            <div className=" h-12 w-12 flex items-center justify-center text-blue-darker">
                              <FontAwesomeIcon
                                className=""
                                icon={['far', 'plus']}
                                size="sm"
                              />
                            </div>
                            <div className="ml-1 flex-1 text-green-light">
                              Create another account?
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <Link to="/dashboard" onClick={() => setMobileOpen(false)}>
                    Dashboard
                  </Link>
                </li>
                <li>
                  <div className="block text-grey-dark text-xs pt-2">CRM</div>
                </li>
                <li>
                  <Link to="/donors" onClick={() => setMobileOpen(false)}>
                    Donors
                  </Link>
                </li>
                <li>
                  <Link to="/donations" onClick={() => setMobileOpen(false)}>
                    Donations
                  </Link>
                </li>
                <li>
                  <Link
                    to="/recurring_plans"
                    onClick={() => setMobileOpen(false)}
                  >
                    Recurring Plans
                  </Link>
                </li>
                <li>
                  <div className="block text-grey-dark text-xs pt-2">
                    FUNDRAISING
                  </div>
                </li>
                <li>
                  <Link to="/campaigns" onClick={() => setMobileOpen(false)}>
                    Campaigns
                  </Link>
                </li>
                <li>
                  <Link to="/fundraisers" onClick={() => setMobileOpen(false)}>
                    Fundraisers
                  </Link>
                </li>
                <li>
                  <Link to="/forms" onClick={() => setMobileOpen(false)}>
                    Forms
                  </Link>
                </li>
                <li>
                  <Link
                    to="/text-engagement"
                    onClick={() => setMobileOpen(false)}
                  >
                    Text Engagement
                  </Link>
                </li>
                <li>
                  <div className="block text-grey-dark text-xs pt-2">
                    SETTINGS
                  </div>
                </li>
                <li>
                  <Link
                    to="/settings/account_settings"
                    onClick={() => setMobileOpen(false)}
                  >
                    Account Settings
                  </Link>
                </li>
                <li>
                  <Link
                    to="/integrations/zapier_integrations"
                    onClick={() => setMobileOpen(false)}
                  >
                    Integrations
                  </Link>
                </li>
                {isRestrictedForFreePlans && (
                  <li>
                    <Link
                      to="/settings/billing"
                      onClick={() => setMobileOpen(false)}
                    >
                      Upgrade
                    </Link>
                  </li>
                )}
                <ContactSupport />
                <li className="border-b border-t border-grey-light py-4">
                  <Link
                    to="/my_profile/dashboard"
                    className="my-4"
                    onClick={goToProfile}
                  >
                    My Profile
                  </Link>
                </li>
                <li>
                  <a className="cursor-pointer my-4" onClick={goToLogout}>
                    Log Out
                  </a>
                </li>
              </ul>
            )}

            {isDonor === 'true' && (
              <ul className="w-full mobile-nav-ul m-auto bg-white list-reset pt-8">
                <li>
                  <Link
                    to="/my_profile/dashboard"
                    onClick={() => setMobileOpen(false)}
                  >
                    My Dashboard
                  </Link>
                </li>
                <li>
                  <div className="block text-grey-dark text-xs pt-2">
                    TRANSACTIONS
                  </div>
                </li>
                <li>
                  <Link
                    to="/my_profile/donations"
                    onClick={() => setMobileOpen(false)}
                  >
                    My Donations
                  </Link>
                </li>
                <li>
                  <Link
                    to="/my_profile/recurring_plans"
                    onClick={() => setMobileOpen(false)}
                  >
                    My Recurring Plans
                  </Link>
                </li>
                <li>
                  <div className="block text-grey-dark text-xs pt-2">
                    FUNDRAISING
                  </div>
                </li>
                <li>
                  <Link
                    to="/my_profile/fundraisers"
                    onClick={() => setMobileOpen(false)}
                  >
                    My Fundraisers
                  </Link>
                </li>
                <li>
                  <div className="block text-grey-dark text-xs pt-2">
                    SETTINGS
                  </div>
                </li>
                <li>
                  <Link
                    to="/my_profile/settings"
                    onClick={() => setMobileOpen(false)}
                  >
                    My Settings
                  </Link>
                </li>
                <li>
                  <Link
                    to="/my_profile/tax_report"
                    onClick={() => setMobileOpen(false)}
                  >
                    My Tax Report
                  </Link>
                </li>
                <li className="border-t border-grey-light mt-4">
                  <a
                    className="cursor-pointer my-4"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://donately.com/contact"
                  >
                    Contact Support
                  </a>
                </li>
                <li className="border-b border-t border-grey-light py-4">
                  <Link to="/dashboard" onClick={goToAccountDashboard}>
                    Account Dashboard
                  </Link>
                </li>
                <li className="">
                  <a className="cursor-pointer" onClick={goToLogout}>
                    Log Out
                  </a>
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavMobile;
