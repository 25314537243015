import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import { getUserAvatar, setDashboardView } from '../../../library/functions';
import useClickOutside from '../../../library/hooks/useClickOutside';

const HelperNavbarSection = () => {
  const helperNavRef = useRef(null);
  useClickOutside({
    ref: helperNavRef,
    callback: () => setHelperMenuOpen(false),
  });
  const { selectedAccount, isDonor } = useSelector(
    state => state.accountReducer
  );
  const [helperMenuOpen, setHelperMenuOpen] = useState(false);
  const toggleProfileMenu = () => {
    setHelperMenuOpen(!helperMenuOpen);
  };
  const toggleTeamView = () => {
    setDashboardView(
      localStorage.getItem('dashboardView') === 'team' ? 'org' : 'team'
    );
    setHelperMenuOpen(false);
  };
  const togglePromoCode = promoCode => {
    const dntlyMaketing = window.DonatelyMarketing;
    // console.log('promoCode1', {promoCode});
    if (dntlyMaketing) {
      const sourceData = dntlyMaketing.marketing_storage.getSourceData();
      const { dntly_promo_code: dntlyPromoCode } = sourceData;
      console.log('promoCode2', { dntlyPromoCode }, { sourceData });
      dntlyMaketing.marketing_storage.deleteDntlyCookie();
      if (promoCode && promoCode !== dntlyPromoCode) {
        dntlyMaketing.marketing_storage.trackParams({ dpc: promoCode });
      }
    }
    setHelperMenuOpen(false);
  };
  const { userSettings: currentUserSettings } = useSelector(
    state => state.myProfileReducer
  );
  return (
    <div className="dropdown my-auto" ref={helperNavRef}>
      {selectedAccount && (
        <button
          className="pr-4 pt-2 dropbtn inline-flex align-top"
          id="info-dropdown"
          onClick={toggleProfileMenu}
        >
          <FontAwesomeIcon icon={['fal', 'question-circle']} size="lg" />
        </button>
      )}
      {isDonor === 'false' && history.location.pathname !== '/onboarding' && (
        <Link
          className="pr-4 pt-2 dropbtn inline-flex align-top"
          to="/referrals"
        >
          <FontAwesomeIcon icon={['fal', 'gift']} size="lg" />
        </Link>
      )}
      <div className="pr-4 inline-flex align-bottom">
        {getUserAvatar() && (
          <img className="h-6 w-6 rounded-full" src={getUserAvatar()} alt="" />
        )}
      </div>
      {helperMenuOpen && (
        <div className="dropdown-content-right-helper" ref={helperNavRef}>
          <ul className="list-reset">
            <li>
              <a
                className="nav-link"
                href="https://share.hsforms.com/1u4V8idbYQFukwjXvRabJOQby8td"
                target="_blank"
                rel="noreferrer noopener"
              >
                Submit a Bug
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="https://support.donately.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                Help Center
              </a>
            </li>
            <li>
              {localStorage.getItem('apiUpdate') === 'true' &&
                selectedAccount.billing.subscription_plan !== 'Free' && (
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://donately.com/contact"
                  >
                    Contact Support
                  </a>
                )}
              {localStorage.getItem('apiUpdate') === 'true' &&
                selectedAccount.billing.subscription_plan === 'Free' && (
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://share.hsforms.com/1u4V8idbYQFukwjXvRabJOQby8td"
                  >
                    Contact Support
                  </a>
                )}
            </li>
            <li>
              <a
                className="nav-link"
                href="https://docs-api-v20190315.donately.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                API Documentation
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="https://donately.com/terms-of-use/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </li>
            {currentUserSettings.donately_team_member && (
              <li className="border-t border-grey-light pt-4">
                <ul className="p-0 list-reset">
                  <li>
                    <a className="nav-link" onClick={toggleTeamView}>
                      Turn Team View{' '}
                      <b>
                        {localStorage.getItem('dashboardView') === 'team'
                          ? 'Off'
                          : 'On'}
                      </b>
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link"
                      onClick={() => togglePromoCode('starterPlus')}
                    >
                      Toggle Starter Plus Promo
                    </a>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default HelperNavbarSection;
