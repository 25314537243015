import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import { getUserAvatar, getUserName } from '../../../library/functions';
import useClickOutside from '../../../library/hooks/useClickOutside';
import { isDonor as isDonorAction } from '../user-accounts.actions';

const ProfileNavbarSection = () => {
  const profileMenuRef = useRef(null);
  const { selectedAccount, isDonor } = useSelector(
    state => state.accountReducer
  );
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const { userSettings: currentUserSettings } = useSelector(
    state => state.myProfileReducer
  );
  const dispatch = useDispatch();
  useClickOutside({
    ref: profileMenuRef,
    callback: () => setProfileMenuOpen(false),
  });
  const toggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };
  const goToProfile = () => {
    localStorage.setItem('isDonor', 'true');
    dispatch(isDonorAction('true'));
    document
      .getElementById('changing-header')
      .classList.add('donor-background');
    document
      .getElementById('changing-header')
      .classList.remove('topnav-blue-background');
  };
  const goToAccountDashboard = () => {
    localStorage.setItem('isDonor', 'false');
    dispatch(isDonorAction('false'));
    document
      .getElementById('changing-header')
      .classList.add('topnav-blue-background');
    document
      .getElementById('changing-header')
      .classList.remove('donor-background');
  };

  return (
    <div className="dropdown my-auto">
      <button
        className="pr-8 dropbtn temp-border top-nav-name-width"
        id="user-dropdown"
        onClick={toggleProfileMenu}
      >
        {getUserName(currentUserSettings)}
        <FontAwesomeIcon icon={['far', 'angle-down']} className="ml-2" />
      </button>
      {profileMenuOpen && (
        <div
          className="dropdown-content-right overflow-y-auto  m-h-72 rounded py-12 "
          ref={profileMenuRef}
        >
          <ul className="list-reset">
            <li className="px-12">
              <div className="flex flex-col items-center justify-center text-grey-darker">
                <div>
                  {!currentUserSettings ? (
                    <img alt="" />
                  ) : (
                    <>
                      {getUserAvatar() && (
                        <img
                          className="h-12 w-12 rounded-full"
                          src={getUserAvatar()}
                          alt=""
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="py-3 flex flex-col justify-center items-center">
                  <div className="text-xl">
                    {currentUserSettings.first_name &&
                      currentUserSettings.first_name.charAt(0).toUpperCase() +
                        currentUserSettings.first_name.slice(1)}{' '}
                    {currentUserSettings.last_name &&
                      currentUserSettings.last_name.charAt(0).toUpperCase() +
                        currentUserSettings.last_name.slice(1)}
                  </div>
                  <div className="text-grey-dark text-sm">
                    {currentUserSettings.email}
                  </div>
                </div>
              </div>
            </li>
            {selectedAccount && <hr className="border-pale-blue w-full p-0" />}
            <li className="px-12 py-4">
              {isDonor === 'false' && (
                <>
                  <ul className="p-0">
                    <li>
                      <Link
                        to="/my_profile/dashboard"
                        className="select-account flex nowrap text-teal hover:text-grey-darker items-center cursor-pointer no-underline"
                        onClick={goToProfile}
                      >
                        <div className=" h-8 w-8 flex items-center justify-center ">
                          <FontAwesomeIcon
                            className=""
                            icon={['far', 'user']}
                            size="xs"
                          />
                        </div>
                        <div className="text-xs ml-1 flex-1 hover:text-grey-darker">
                          View your donor profile
                        </div>
                      </Link>
                    </li>
                    <li
                      className="select-account flex nowrap text-teal hover:text-grey-darker items-center cursor-pointer "
                      onClick={() =>
                        history.push({
                          pathname: '/create_account',
                          state: { newAccount: true },
                        })
                      }
                    >
                      <div className=" h-8 w-8 flex items-center justify-center ">
                        <FontAwesomeIcon
                          className=""
                          icon={['far', 'plus']}
                          size="xs"
                        />
                      </div>
                      <div className="text-xs ml-1 flex-1 hover:text-grey-darker">
                        Create another account?
                      </div>
                    </li>
                  </ul>
                </>
              )}
              {isDonor === 'true' && selectedAccount && (
                <li>
                  <Link
                    to="/dashboard"
                    className="select-account flex nowrap text-teal hover:text-grey-darker items-center cursor-pointer no-underline"
                    onClick={goToAccountDashboard}
                  >
                    <div className=" h-8 w-8 flex items-center justify-center ">
                      <FontAwesomeIcon
                        className=""
                        icon={['far', 'user']}
                        size="xs"
                      />
                    </div>
                    <div className="text-xs ml-1 flex-1 hover:grey-darker">
                      View your account dashboard
                    </div>
                  </Link>
                </li>
              )}
            </li>
            <hr className="border-pale-blue w-full p-0" />
            <li className="select-account flex nowrap text-teal items-center justify-center px-12 pt-6 cursor-pointer">
              <a
                className="hover:text-grey-darker mr-3"
                onClick={() => history.push({ pathname: '/logout' })}
              >
                Log Out
              </a>
            </li>
            <li className="text-xs px-12 pt-6 flex-1 flex flex-row items-center justify-center space-between">
              <a
                className="hover:text-grey-darker mr-3 no-underline text-teal"
                href="https://donately.com/privacy-policy/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>

              <div className="text-grey-dark">•</div>
              <a
                className="hover:text-grey-darker ml-3 no-underline text-teal"
                href="https://donately.com/terms-of-use/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms of use
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProfileNavbarSection;
