import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './app/app.store';
import Auth0Container from './Auth0Container';

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <Auth0Container />
  </Provider>
);

export default App;
