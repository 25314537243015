import {
  selectedAccount,
  fetchAccount,
  pushToCRM,
} from '../user-accounts/user-accounts.actions';
import { receiveFail } from '../donations/donations.actions';

import { rebuildApiHeaders } from '../../library/functions';
import { showMessage } from '../modal/modal.actions';

// Requests all settings for the account from the API
export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';

export function requestSettings(accountIdentifier, headers) {
  return {
    type: REQUEST_SETTINGS,
    account_identifier: accountIdentifier,
    headers,
  };
}

// Receives the settings from the API
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';

export function receiveSettings(accountIdentifier, json) {
  return {
    type: RECEIVE_SETTINGS,
    account_identifier: accountIdentifier,
    settings: json.data,
    receivedAt: Date.now(),
  };
}

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';

export function requestNotifications(accountIdentifier, headers) {
  return {
    type: REQUEST_NOTIFICATIONS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';

export function receiveNotifications(accountIdentifier, json) {
  return {
    type: RECEIVE_NOTIFICATIONS,
    account_identifier: accountIdentifier,
    notifications: json.data,
  };
}

export const RECEIVE_ACCOUNT_CC = 'RECEIVE_ACCOUNT_CC';

export function receiveAccountCC(json) {
  return {
    type: RECEIVE_ACCOUNT_CC,
    accountCC: json,
  };
}

export const INVALIDATE_SETTING = 'INVALIDATE_SETTING';

export function invalidateSetting(setting) {
  return {
    type: INVALIDATE_SETTING,
    setting,
  };
}

// Prepares edited Settings to API
export const EDIT_SETTINGS = 'EDIT_SETTINGS';

export function editSettings(headers) {
  return {
    type: EDIT_SETTINGS,
    headers,
  };
}

// Makes API call to fetch Settings for account
export function fetchSettings(accountIdentifier, headers) {
  return dispatch => {
    if (!accountIdentifier) {
      return new Promise(resolve => resolve(null));
    }
    dispatch(requestSettings(accountIdentifier, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}.json`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    )
      .then(function(response) {
        return response.json();
      })
      .then(json => {
        if (json.error) {
          dispatch(receiveFail(json.error));
        } else {
          dispatch(receiveSettings(accountIdentifier, json));
        }
      });
  };
}

export const UPDATE_SCRIPT_TAGS_SUCCESS = 'UPDATE_SCRIPT_TAGS_SUCCESS';

export function updateSettings(
  accountIdentifier,
  headers,
  updateValues,
  onSuccess = null,
  onError = null
) {
  return async (dispatch, getState) => {
    const state = getState();
    await dispatch(editSettings(headers));

    const currentScriptTags = state.accountReducer.selectedAccount.script_tags;
    const newUpdateValues = {
      ...updateValues,
      script_tags: {
        ...currentScriptTags,
        ...updateValues.script_tags,
      },
    };

    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(newUpdateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    const json = await response.json();

    if (response.status !== 200) {
      if (onError) {
        onError(json.message);
      }
      await dispatch(receiveFail(json.message));
    } else {
      await dispatch({
        type: UPDATE_SCRIPT_TAGS_SUCCESS,
        script_tags: newUpdateValues.script_tags,
      });
      await dispatch(selectedAccount(json.data));
      await dispatch(fetchSettings(accountIdentifier, headers));
      await dispatch(showMessage('Account settings successfully updated'));
      if (onSuccess) {
        onSuccess('Account settings successfully udpdated.');
      }
    }
  };
}

export function updateAccountCC(accountIdentifier, headers, updateValues) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}/payment_sources.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response
        .json()
        .then(
          json => dispatch(receiveAccountCC(json.data)),
          dispatch(receiveFail('CC successfully updated.'))
        );
    });
}

export function fetchAccountCC(accountIdentifier, headers) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}/payment_sources.json`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('fetchAccountCC error', { response });
      } else {
        return response
          .json()
          .then(json => dispatch(receiveAccountCC(json.data)));
      }
    });
}

export function updateAccountSubscription(
  accountIdentifier,
  headers,
  planName,
  paymentInterval
) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}/subscriptions.json?plan_name=${planName}&pymt_interval=${paymentInterval}`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );
    const json = await response.json();
    if (response.status !== 200) {
      dispatch(receiveFail(json.message));
      return [false, json.message];
    }
    await dispatch(selectedAccount(json.data));
    await dispatch(fetchSettings(accountIdentifier, headers));
    await dispatch(receiveFail('Account successfully upgraded!'));
    return [true];
  };
}

// Checks if call should be made to fetch settings if the fetch is not already happening
export function shouldFetchSettings(state, accountIdentifier) {
  const settings = state.settingsReducer.settingsByAccount[accountIdentifier];
  if (!settings) {
    return true;
  }
  if (settings.isFetching) {
    return false;
  }
}

// Checks if settings exist in state before dispatching the API fetch
export function fetchSettingsIfNeeded(accountIdentifier, headers) {
  return (dispatch, getState) => {
    if (shouldFetchSettings(getState(), accountIdentifier)) {
      return dispatch(fetchSettings(accountIdentifier, headers));
    }
  };
}

// API Fetch to request and receive Admins on account
export function fetchAdmins(accountIdentifier, headers) {
  return async dispatch => {
    await dispatch(requestAdmins(accountIdentifier, headers));
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/people.json?account_id=${accountIdentifier}&roles=admin`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );
    const json = await response.json();
    if (json.error) {
      dispatch(receiveFail(json.error));
    }
    dispatch(receiveAdmins(accountIdentifier, json));
  };
}

// Check to only fetch API if it is not already fetching
export function shouldFetchAdmins(state, accountIdentifier) {
  const admins = state.settingsReducer.adminsByAccount[accountIdentifier];
  if (!admins) {
    return true;
  }
  if (admins.isFetching) {
    return false;
  }
}

// Fetch Admins
export function fetchAdminsIfNeeded(accountIdentifier, headers) {
  return (dispatch, getState) => {
    // console.log(
    //   'fetchAdminsIfNeeded',
    //   { shouldFetchAdmins: shouldFetchAdmins(getState(), accountIdentifier) },
    //   { accountIdentifier }
    // );
    if (shouldFetchAdmins(getState(), accountIdentifier)) {
      return dispatch(fetchAdmins(accountIdentifier, headers));
    }
  };
}

// API fetch to edit admin properties
export function updateAdmins(
  accountIdentifier,
  headers,
  updateValues,
  onSuccess = null,
  onError = null
) {
  return async dispatch => {
    await dispatch(editAdmin(accountIdentifier, headers));
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}/admins.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        json: true,
        body: JSON.stringify(updateValues),
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    const json = await response.json();
    if (response.status !== 200) {
      if (onError) {
        onError(json.message);
      }
      return dispatch(receiveFail(json.message));
    }

    if (onSuccess) {
      onSuccess(`${json.params.email} successfully added.`);
    }

    await dispatch(receiveFail(`${json.params.email} successfully added.`));
    return dispatch(fetchAdmins(accountIdentifier, headers));
  };
}

// API fetch to remove Admins as requested by user
export function removeAdmin(accountIdentifier, headers, adminId, adminName) {
  return dispatch => {
    dispatch(editAdmin(accountIdentifier, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}/admins/${adminId}.json?account_id=${accountIdentifier}`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(json => {
        dispatch(receiveFail(`${adminName} successfully deleted.`));
        dispatch(fetchAdmins(accountIdentifier, headers));
      });
    });
  };
}

export const REQUEST_ADMINS = 'REQUEST_ADMINS';

export function requestAdmins(accountIdentifier, headers) {
  return {
    type: REQUEST_ADMINS,
    account_identifier: accountIdentifier,
    headers,
  };
}

export const RECEIVE_ADMINS = 'RECEIVE_ADMINS';

export function receiveAdmins(accountIdentifier, json) {
  return {
    type: RECEIVE_ADMINS,
    account_identifier: accountIdentifier,
    admins: json.data,
    receivedAt: Date.now(),
  };
}

export const EDIT_ADMIN = 'EDIT_ADMIN';

export function editAdmin(headers) {
  return {
    type: EDIT_ADMIN,
    headers,
  };
}

export const RECEIVE_STRIPE_KEYS = 'RECEIVE_STRIPE_KEYS';
export const RECEIVE_MERCHANT_STATUS = 'RECEIVE_MERCHANT_STATUS';

export function receiveMerchantStatus(paypalMerchantSettings) {
  return {
    type: RECEIVE_MERCHANT_STATUS,
    paypal_merchant_settings: paypalMerchantSettings,
  };
}

export function getNotifications(accountIdentifier, headers) {
  return dispatch => {
    dispatch(requestNotifications(accountIdentifier, headers));
    return fetch(
      `${process.env.DONATELY_API_V2_URL}/notifications.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response
        .json()
        .then(json => dispatch(receiveNotifications(accountIdentifier, json)));
    });
  };
}

export function createNotifications(
  accountIdentifier,
  headers,
  adminId,
  type,
  frequency,
  event,
  onSuccess = null,
  onError = null
) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/notifications.json?account_id=${accountIdentifier}&person_id=${adminId}&notification_type=${type}&frequency=${frequency}&event=${event}`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response.json().then(json => {
          if (onError) {
            onError(json.message);
          }
          dispatch(receiveFail(json.message));
        });
      }
      return response.json().then(json => {
        if (onSuccess) {
          onSuccess(`Notification successfully created.`);
        }
        dispatch(receiveFail(`Notification successfully created.`));
        dispatch(getNotifications(accountIdentifier, headers));
      });
    });
}

export function removeNotification(
  accountIdentifier,
  headers,
  notificationId,
  adminName
) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/notifications/${notificationId}.json?account_id=${accountIdentifier}`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    const json = await response.json();

    if (response.status !== 200) {
      return dispatch(receiveFail(json.message));
    }

    await dispatch(
      receiveFail(`${adminName}'s notification successfully deleted.`)
    );
    return dispatch(getNotifications(accountIdentifier, headers));
  };
}

export function getCharges(accountIdentifier, headers) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/charges.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json();
      // .then(json =>
      //   dispatch(receiveNotifications(account_identifier, json))
      // )
    });
}

// doesn't take you to stripe
export function createDeferredStripe(
  accountIdentifier,
  headers,
  email,
  country
) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/stripe/create_deferred_stripe_account.json`,
      {
        method: 'POST',
        json: true,
        body: JSON.stringify({
          account_id: accountIdentifier,
          email,
          country,
        }),
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    const responseJson = await response.json();

    if (response.status !== 200) {
      return dispatch(receiveFail(responseJson.message));
    }

    /*
    let's not push to CRM for now, because were doing it from the API as well.
    2024/08/20
    */
    // dispatch(selectedAccount(responseJson.data));
    // return dispatch(pushToCRM(headers, responseJson.data));
  };
}

// return URL which goes to stripe, this is for 'link different stripe account'
export function connectToStripe(accountIdentifier, headers) {
  return async (dispatch, getState) => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/stripe/create_stripe_oauth_request.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    /*
      let's not push to CRM for now, because were doing it from the API as well.
      2024/08/20
      */

    // const newState = getState();
    // dispatch(pushToCRM(headers, newState.accountReducer.selectedAccount));

    const responseJson = await response.json();
    return responseJson;
  };
}

// returns url, which takes you to Stripe, only for deferred mode
export function activateStripe(accountIdentifier, headers) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/stripe/get_account_link.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      return response.json();
    });
}

// update the account after returning from Stripe activation
export function activateStripeReturn(
  accountIdentifier,
  headers,
  stripeResponse
) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/stripe/activate_stripe_account.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        json: true,
        body: JSON.stringify(stripeResponse),
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('activateStripeReturn error', { response });
      } else {
        return response.json().then(json => {
          dispatch(fetchAccount(headers, accountIdentifier));
          dispatch(fetchSettings(accountIdentifier, headers));
        });
      }
    });
}

// update the account after connecting to Stripe
export function sendStripeInfo(accountIdentifier, headers, stripeResponse) {
  return async dispatch => {
    try {
      const response = await fetch(
        `${process.env.DONATELY_API_V2_URL}/partners/stripe/parse_stripe_oauth_response.json?account_id=${accountIdentifier}`,
        {
          method: 'POST',
          json: true,
          body: JSON.stringify(stripeResponse),
          rejectUnauthorized: false,
          headers: rebuildApiHeaders(headers),
        }
      );

      const responseJson = await response.json();

      if (response.status !== 200) {
        console.log(
          'stripe oauth response error ',
          { response },
          { responseJson }
        );
        // dispatch(receiveFail(responseJson.message));
        dispatch(receiveFail('Network error. Please try again.'));
        return false;
      }

      dispatch(fetchAccount(headers, accountIdentifier));
      dispatch(fetchSettings(accountIdentifier, headers));
      return true;
    } catch (error) {
      console.log('stripe oauth network error ', { error });
      dispatch(receiveFail('Network error. Please try again.'));
      return false;
    }
  };
}

export function sendTestEmail(accountIdentifier, headers) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/notifications/send_test_email_receipt.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    const json = await response.json();

    if (response.status !== 200) {
      return dispatch(receiveFail(json.message));
    }

    return dispatch(showMessage('Test email successfully sent.'));
  };
}

export function clearTestDonations(accountIdentifier, headers) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}/test_donations.json`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(json => {
        dispatch(receiveFail('Test Donations successfully cleared.'));
      });
    });
}

export function updateStripeKeys(accountIdentifier, headers, updateValues) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/stripe/update_account.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        json: true,
        body: JSON.stringify(updateValues),
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response
          .json()
          .then(json => dispatch(receiveFail(json.message)));
      }
      return response.json().then(json => {
        dispatch(fetchAccount(headers, accountIdentifier));
        // dispatch(getStripeKeys(accountIdentifier, headers));
        dispatch(receiveFail('Stripe Keys successfully updated.'));
      });
    });
}

export function getPaypalUrl(accountIdentifier, headers) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/paypal/referrals_url.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('getPaypalUrl error', { response });
        dispatch(
          receiveFail(`Paypal URL obtaining error: ${response.statusText}`)
        );
      }
      return response.json();
    });
}

export function getMerchantId(accountIdentifier, headers) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/paypal/merchant_id.json?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        return response.json();
      }
      return response.json();
    });
}

export function getMerchantStatus(accountIdentifier, merchantID, headers) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/paypal/merchant_status.json?account_id=${accountIdentifier}&merchant_id=${merchantID}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('getMerchantStatus error', { response });
        return response.json();
      }
      return response.json();
    });
}

export function updatePaypalMerchantSettings(
  accountIdentifier,
  headers,
  paypalResponse
) {
  return dispatch =>
    fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/paypal/update_paypal_connect_status?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        json: true,
        body: JSON.stringify(paypalResponse),
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    ).then(function(response) {
      if (response.status !== 200) {
        console.log('updatePaypalMerchantSettings error', { response });
        dispatch(receiveFail(response));
      } else {
        return response.json().then(json => {
          dispatch(fetchAccount(headers, accountIdentifier));
          dispatch(fetchSettings(accountIdentifier, headers));
        });
      }
    });
}

export function downgradeAccountSubscription(
  accountIdentifier,
  headers,
  planName,
  paymentInterval
) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}/subscriptions.json?plan_name=${planName}&pymt_interval=${paymentInterval}`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    const json = await response.json();
    if (response.status !== 200) {
      return dispatch(receiveFail(json.message));
    }

    await dispatch(selectedAccount(json.data));
    await dispatch(fetchSettings(accountIdentifier, headers));
    return receiveFail('Account successfully downgraded!');
  };
}

export function removeImage(accountIdentifier, param, headers) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/accounts/${accountIdentifier}/remove_image?${param}=true`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    const json = await response.json();

    if (response.status !== 200) {
      return dispatch(receiveFail(json.message));
    }

    await dispatch(selectedAccount(json.data));
    await dispatch(fetchSettings(accountIdentifier, headers));
    return receiveFail('Image successfully removed!');
  };
}

export function getStripeAccountInfo(accountIdentifier, headers) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/partners/stripe/get_stripe_account?account_id=${accountIdentifier}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    if (response.status !== 200) {
      const errorResponse = await response.json();
      dispatch(receiveFail(errorResponse.message));
      return errorResponse.message;
    }

    const successResponse = await response.json();
    return successResponse;
  };
}
