import React from 'react';
import ReactDOM from 'react-dom';
import { TrackJS } from 'trackjs';
import * as Yup from 'yup';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './app/index.css';
import './app/index.tailwind.css';
import '../node_modules/froala-editor/css/froala_editor.pkgd.min.css';
import '../node_modules/froala-editor/css/froala_style.min.css';
import '../node_modules/froala-editor/js/plugins.pkgd.min.js';
import App from './App';

// if we're in dev mode
if (process.env.NODE_ENV === 'development') {
  // Set up react-axe to catch accessibility issues during development
  /* eslint-disable */
  const axe = require('react-axe');
  /* eslint-enable */
  if (!process.env.AXE_OFF) {
    axe(React, ReactDOM, 1000);
  }
}

TrackJS.install({
  token: '72e0e06ec183489e97c7e33b6c481c16',
  application: 'donately-dashboard',
});

// add method to yup
Yup.addMethod(Yup.object, 'optional', function(
  isOptional = true,
  defaultValue = undefined
) {
  return (
    this.transform(function(value) {
      // If false is passed, skip the transform
      if (!isOptional) return value;

      // If any child property has a value, skip the transform
      if (
        value &&
        Object.values(value).some(
          v => !(v === null || v === undefined || v === '')
        )
      ) {
        return value;
      }

      return defaultValue;
    })
      // Remember, since we're dealing with the `object`
      // type, we have to change the default value
      .default(defaultValue)
  );
});

ReactDOM.render(<App />, document.getElementById('root'));
