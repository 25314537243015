import { rebuildApiHeaders } from '../../../library/functions';
import * as Queries from '../../../library/query.module';
import { receiveFail } from '../../donations/donations.actions';
import { showMessage } from '../../modal/modal.actions';

export function getForms(data) {
  return async () => {
    const { headers, params } = data;
    const url = new URL(`${process.env.DONATELY_API_V2_URL}/forms.json`);
    if (params) {
      Object.keys(params).forEach(key => {
        url.searchParams.append(key, params[key]);
      });
    }

    await fetch(url.toString(), {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers,
    });
  };
}

export const REQUEST_FORM = 'REQUEST_FORM';
export function requestForm(headers, formId) {
  return {
    type: REQUEST_FORM,
    headers,
    formId,
  };
}

export const REQUEST_FORMS = 'REQUEST_FORMS';
export function requestForms(accountIdentifier, headers) {
  return {
    type: REQUEST_FORMS,
    headers,
    account_identifier: accountIdentifier,
  };
}

export const RECEIVE_FORMS = 'RECEIVE_FORMS';
export function receiveForms(accountIdentifier, json) {
  return {
    type: RECEIVE_FORMS,
    account_identifier: accountIdentifier,
    forms: json.data,
    summary: json.summary,
    orderBy: json.params,
    receivedAt: Date.now(),
  };
}

export const SET_FORM_QUERY = 'SET_FORM_QUERY';
export function setFormQuery(query) {
  return {
    type: SET_FORM_QUERY,
    query,
  };
}

export const SELECTED_FORM = 'SELECTED_FORM';
export function selectedForm(json) {
  return {
    type: SELECTED_FORM,
    form: json,
    receivedAt: Date.now(),
  };
}

export function fetchForms(offset = '0', query = {}, limit = '20') {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        storeUser: headers,
        selectedAccount: { id: accountIdentifier },
      },
    } = getState();
    let argQuery = query;

    if (!argQuery.order_by) {
      argQuery = { ...argQuery, order_by: 'created_at', order: 'desc' };
    }
    const url = Queries.getFormsQuery(
      accountIdentifier,
      offset,
      argQuery,
      limit
    );
    await dispatch(requestForms(accountIdentifier, headers));
    const response = await fetch(url, {
      method: 'GET',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
    });

    if (response.status !== 200) {
      return console.log('error');
    }
    const responseJson = await response.json();
    await dispatch(receiveForms(accountIdentifier, responseJson));
    await dispatch(setFormQuery(argQuery));

    return responseJson;
  };
}

export function fetchForm(headers, accountId, formId) {
  return async dispatch => {
    await dispatch(requestForm(headers, accountId, formId));
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/forms/${formId}.json?account_id=${accountId}`,
      {
        method: 'GET',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );
    if (response.status !== 200) {
      // return console.log('error');
    }
    const json = await response.json();
    if (json.data) {
      await dispatch(selectedForm(json.data));
    }
  };
}

export function makeFormDefault(accountId, headers, formId, offset, query) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/forms/${formId}/make_form_default.json?account_id=${accountId}`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    const responseJson = await response.json();

    console.log(
      'makeFormDefault',
      response.status,
      { responseJson },
      { response }
    );

    if (responseJson.code !== 200) {
      dispatch(showMessage(responseJson.message, 'error'));
    }

    await dispatch(
      showMessage('Forms successfully updated', 'success', null, true)
    );
    await dispatch(fetchForms(offset, query));
  };
}

export const DELETE_FORM = 'DELETE_FORM';
export function deleteForm(accountId, headers, formId, offset, query) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/forms/${formId}.json?account_id=${accountId}`,
      {
        method: 'DELETE',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    if (response.status !== 200) {
      dispatch(showMessage('Form could not be deleted', 'error'));
    }

    await dispatch(
      showMessage('Form successfully deleted', 'success', null, true)
    );
    await dispatch(fetchForms(offset, query));
  };
}

export async function sendTestEcard(
  headers,
  accountId,
  title = '',
  content = '',
  footer = '',
  ecardSettings = ''
) {
  const response = await fetch(
    `${process.env.DONATELY_API_V2_URL}/notifications/send_test?type=ecard`,
    {
      method: 'POST',
      json: true,
      rejectUnauthorized: false,
      headers: rebuildApiHeaders(headers),
      body: JSON.stringify({
        account_id: accountId,
        type: 'ecard',
        ecard_subject: title,
        ecard_content: content,
        ecard_footer: footer,
        ecard_settings: ecardSettings,
      }),
    }
  );

  if (response.status !== 200) {
    return 'An error ocurred when sending the test e-card';
  }
  return 'The e card test has been sent successfully';
}

export const ADD_FORM_TO_STATE = 'ADD_FORM_TO_STATE';
export function addFormToState(form) {
  return {
    type: ADD_FORM_TO_STATE,
    form,
  };
}

export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';
export const CREATE_FORM_FAILURE = 'CREATE_FORM_FAILURE';

export function createForm(
  accountIdentifier,
  headers,
  updateValues,
  onSuccess = null,
  onFail = null
) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/forms.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    if (response.status !== 200) {
      dispatch({
        type: CREATE_FORM_FAILURE,
      });
      dispatch(
        showMessage('An error ocurred while creating the form', 'error')
      );
      if (onFail) {
        onFail();
      }
    } else {
      const responseJson = await response.json();
      await dispatch(selectedForm(responseJson.data));
      await dispatch(addFormToState(responseJson.data));
      if (onSuccess) {
        onSuccess(responseJson.data);
      }
    }
  };
}

export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS';
export const UPDATE_FORM_FAILURE = 'UPDATE_FORM_FAILURE';

export function updateForm(
  accountIdentifier,
  headers,
  formId,
  updateValues,
  onSuccess = null
) {
  return async dispatch => {
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/forms/${formId}.json?account_id=${accountIdentifier}`,
      {
        method: 'POST',
        body: JSON.stringify(updateValues),
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    const responseJson = await response.json();
    if (response.status !== 200) {
      dispatch(
        showMessage(
          `Error while updating form: ${responseJson.message}`,
          'error'
        )
      );
    } else {
      await dispatch(selectedForm(responseJson.data));
      await dispatch(fetchForms());
      if (onSuccess) {
        onSuccess();
      }
    }
  };
}
export function resendEcard(donationIdenetifier) {
  return async (dispatch, getState) => {
    const {
      accountReducer: { storeUser: headers },
    } = getState();
    const response = await fetch(
      `${process.env.DONATELY_API_V2_URL}/donations/${donationIdenetifier}/send_ecard`,
      {
        method: 'POST',
        json: true,
        rejectUnauthorized: false,
        headers: rebuildApiHeaders(headers),
      }
    );

    if (response.status !== 200) {
      dispatch(receiveFail('An error ocurred when resending the e-card'));
    } else {
      dispatch(receiveFail('The e card has been resent successfully'));
    }
  };
}
