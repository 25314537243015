import React from 'react';
import { PropTypes } from 'prop-types';

function Pill(props) {
  const {
    text,
    styledType,
    size,
    customClassName,
    textClassName,
    children,
    ...allOthersProps
  } = props;

  // Add here the buttons types that are available for small sizes
  const smallSizeAvailableButtons = ['danger'];
  // Add here the buttons types that are available for large sizes
  const largeSizeAvailableButtons = ['primary', 'secondary', 'light'];

  const getPillClassNameType = thePillType => {
    switch (thePillType) {
      case 'primary':
        return 'dntly-btn-primary';
      case 'primary-light':
        return 'dntly-pill-primary-light';
      case 'secondary':
        return 'dntly-pill-secondary';
      case 'danger':
        return 'dntly-btn-danger';
      case 'warning':
        return 'dntly-btn-warning';
      case 'warning-outlined':
        return 'dntly-btn-warning outlined';
      case 'danger-filled':
        return 'dntly-btn-danger filled';
      case 'danger-outlined':
        return 'dntly-btn-danger outlined';
      case 'light':
        return 'btn-light';
      case 'upgrade':
        return 'dntly-pill-upsell';
      case 'upgrade-outlined':
        return 'dntly-pill-upsell outlined';
      case 'upgrade-outlined-transparent':
        return 'dntly-pill-upsell outlined transparent';
      case 'primary-search':
        return 'dntly-btn-primary-search';
      case 'secondary-search':
        return 'dntly-btn-secondary-search';
      case 'purple-lightest':
        return 'dntly-pill-purple-lightest';
      case 'none':
        return '';
      default:
        return 'dntly-btn-primary';
    }
  };

  const getPillSize = (thePillSize, thePillType) => {
    switch (thePillSize) {
      case 'default':
        return '';
      case 'small':
        return smallSizeAvailableButtons.includes(thePillType) ? '-small' : '';
      case 'large':
        return largeSizeAvailableButtons.includes(thePillType)
          ? ' dntly-btn-large'
          : '';
      default:
        return '';
    }
  };

  const pillType = getPillClassNameType(styledType);
  const pillSize = getPillSize(size, styledType);

  return (
    <div className={`${pillType}${pillSize} ${customClassName}`}>
      {children || <span className={textClassName}>{text}</span>}
    </div>
  );
}

Pill.defaultProps = {
  text: 'Pill Label',
  styledType: 'primary',
  size: 'default',
  customClassName: '',
  textClassName: '',
  children: null,
};

Pill.propTypes = {
  text: PropTypes.string,
  styledType: PropTypes.string,
  size: PropTypes.string,
  customClassName: PropTypes.string,
  textClassName: PropTypes.string,
  children: PropTypes.any,
};

export default Pill;
