import axios from 'axios';
import { rebuildApiHeaders } from '../app/library/functions';

export async function doRequest(optionsParams) {
  const { headers, ...options } = optionsParams;
  const config = {
    baseURL: `${process.env.DONATELY_API_V2_URL}/`,
    headers: rebuildApiHeaders(headers),
    responseType: 'json',
    ...options,
  };
  const response = await axios.request(config);
  return response?.data;
}
