import { Auth0Provider } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect'; // TODO: We should replace this
import { useDispatch, useSelector } from 'react-redux';
import history from './history';
import FortAwesomeContainer from './FortAwesomeContainer';
import { fetchAllFundraisersRecursively } from './app/authenticated/fundraisers/fundraisers.actions';
import { Routes } from './app/app.routes';

const oAuthUrlsOtherThanAuth0 = [
  'callback/stripe/oauth_authorize',
  'callback/paypal/paypal_authorize',
];

const Auth0Container = () => {
  const { selectedAccount, storeUser } = useSelector(
    state => state.accountReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedAccount && storeUser && storeUser.Authorigin) {
      dispatch(fetchAllFundraisersRecursively(selectedAccount.id, storeUser));
    }
  }, [selectedAccount, storeUser]);

  const onRedirectCallback = (appState, user) => {
    if (user.exp) {
      localStorage.setItem('expires_at', user.exp);
    }

    if (user.name) {
      localStorage.setItem('profile_name', user.name);
    }
    if (user.picture) {
      localStorage.setItem('profile_picture', user.picture);
    }
    if (user.email) {
      localStorage.setItem('profile_email', user.email);
    }
    if (user.nickname) {
      localStorage.setItem('profile_nickname', user.nickname);
    }
    if (user.sub) {
      localStorage.setItem('profile_id', user.sub.split('|')[1]);
    }

    if (typeof CobrowseIO !== 'undefined') {
      window.CobrowseIO.customData = {
        email: user.email,
        name: user.name,
        id: user.sub.split('|')[1],
        last_login: new Date().toISOString(),
      };
    }

    localStorage.setItem('apiUpdate', 'true');
    const appMetadata = user['https://dntly-dash.com/app_metadata'];
    const apiToken =
      (appMetadata && appMetadata.token) ||
      (appMetadata && appMetadata.api_token);

    //   consoleLog(
    //   ' ---> manageLocalStorage',
    //   { mode },
    //   { apiToken },
    //   { appMetadata },
    //   { params }
    // );

    if (apiToken) {
      localStorage.setItem('dntly_api_token', apiToken);
    }
    const returnTo = appState?.returnTo || '';
    const url = new URL(`${window.location.origin}${returnTo}`);
    if (returnTo === '' || url.pathname === '/callback') {
      history.replace('/');
    } else {
      history.replace(returnTo);
    }
  };

  const shouldSkipOauthRedirect = oAuthUrlsOtherThanAuth0.some(url =>
    window.location.pathname.includes(url)
  );

  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      clientId={process.env.AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.AUTH0_AUDIENCE,
        redirect_uri: process.env.AUTH0_REDIRECT_URL,
      }}
      onRedirectCallback={onRedirectCallback}
      shouldSkipOauthRedirect={shouldSkipOauthRedirect}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <Router history={history}>
        <HttpsRedirect>
          <FortAwesomeContainer>
            <Routes />
          </FortAwesomeContainer>
        </HttpsRedirect>
      </Router>
    </Auth0Provider>
  );
};

export default Auth0Container;
