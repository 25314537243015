import { combineReducers } from 'redux';
import {
  INITIATE_CREATE_REPORT,
  INITIATE_FETCH_REPORTS,
  PREPEND_REPORT,
  RECEIVE_REPORTS,
} from './reports.actions';

const initialValue = {
  items: null,
  key: null,
  isFetching: false,
};

function reportsByType(state = {}, action) {
  switch (action.type) {
    case INITIATE_CREATE_REPORT:
      return {
        ...state,
        [action.reportType]: {
          ...state[action.reportType],
          isFetching: true,
        },
      };
    case INITIATE_FETCH_REPORTS:
      return {
        ...state,
        [action.reportType]: {
          ...initialValue,
          isFetching: true,
        },
      };
    case RECEIVE_REPORTS:
      return {
        ...state,
        [action.reportType]: {
          key: action.key,
          items: action.items,
          isFetching: false,
        },
      };
    case PREPEND_REPORT: {
      const itemsByReport = state[action.reportType];
      return {
        ...state,
        [action.reportType]: {
          ...itemsByReport,
          items: [action.report, ...itemsByReport.items],
          isFetching: false,
        },
      };
    }
    default:
      return state;
  }
}

const reportsReducer = combineReducers({
  reportsByType,
});

export default reportsReducer;
