import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../../history';
import TopNavMobile from './TopNavMobile';
import {
  fetchAccount,
  selectedAccount as selectedAccountAction,
} from '../user-accounts.actions';
import useClickOutside from '../../../library/hooks/useClickOutside';
import ProfileNavbarSection from './ProfileNavbarSection';
import HelperNavbarSection from './HelperNavbarSection';
import { setDashboardView } from '../../../library/functions';

const TopNav = () => {
  const accountSelectorRef = useRef(null);
  const { isDonor, selectedAccount, accountsByUser, storeUser } = useSelector(
    state => state.accountReducer
  );
  const { userSettings: currentUserSettings } = useSelector(
    state => state.myProfileReducer
  );
  const [subdomain, setSubdomain] = useState('');
  const [scrollHeight, setScrollHeight] = useState('');
  const [accountsSelectorOpen, setAccountsSelectorOpen] = useState(false);
  const dispatch = useDispatch();
  const { items: accounts } = accountsByUser[storeUser] || {
    items: [],
  };
  const changeAccount = account => {
    dispatch(selectedAccountAction(account));
    setAccountsSelectorOpen(false);
  };
  const onKeyPress = e => {
    if (e.key === 'Enter') {
      dispatch(fetchAccount(storeUser, subdomain));
      setAccountsSelectorOpen(false);
    }
  };
  useClickOutside({
    ref: accountSelectorRef,
    callback: () => {
      setAccountsSelectorOpen(false);
    },
  });
  useEffect(() => {
    if (
      !localStorage.getItem('dashboardView') &&
      currentUserSettings.donately_team_member
    ) {
      setDashboardView('org');
    }
    const updateScroll = () => {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      const nav = document.getElementById('change-topnav-color');
      setScrollHeight(top);
      if (nav && top >= '50') {
        nav.classList.add('scrolled-nav');
        nav.classList.remove('donor-nav');
        nav.classList.remove('account-nav');
      } else if (nav && top <= '50' && isDonor === 'false') {
        nav.classList.add('account-nav');
        nav.classList.remove('donor-nav');
        nav.classList.remove('scrolled-nav');
      } else if (nav && top <= '50' && isDonor === 'true') {
        nav.classList.add('donor-nav');
        nav.classList.remove('account-nav');
        nav.classList.remove('scrolled-nav');
      }
    };
    document.addEventListener('scroll', updateScroll);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('scroll', updateScroll);
    };
  }, []);
  return (
    <div
      id="change-topnav-color"
      className={`sm:h-20 h-16 w-full z-20 flex items-center fixed ${
        isDonor === 'true' ? 'donor-nav' : 'account-background'
      }`}
    >
      <svg
        className="h-4 fill-current mr-2 my-auto pl-3 hidden lg:inline-block w-8"
        viewBox="0 0 24 18.7"
      >
        <path
          d="M23.1,4.7l-3.8-3.8c-1.1-1.1-2.8-1.1-3.8,0L12,4.3L8.6,0.9c-1.1-1.1-2.8-1.1-3.8,0L0.9,4.7
            c-1.1,1.1-1.1,2.8,0,3.8l9.2,9.2c1.1,1.1,2.8,1.1,3.8,0l2.9-2.9l0,0l6.2-6.2C24.2,7.5,24.2,5.8,23.1,4.7z M16.8,1.5
            c0.3-0.3,0.8-0.3,1.1,0l0.7,0.7l-4.4,4.4L13,5.3L16.8,1.5z M22.5,7.2L18.7,11l-3.5-3.5l4.4-4.4l3,3C22.8,6.4,22.8,6.9,22.5,7.2z"
        />
      </svg>
      {isDonor === 'true' && (
        <div className="mt-6 lg:inline-block my-auto hidden">
          Donor Dashboard
        </div>
      )}
      {isDonor === 'false' && selectedAccount && (
        <div className="navbar my-auto hidden lg:inline-block">
          <div className="dropdown my-auto" id="account-dropdown">
            <button
              id="dropbtn"
              className={
                scrollHeight >= '112'
                  ? 'text-grey-darker '
                  : 'text-black pr-8 dropbtn font-normal'
              }
              onClick={() => setAccountsSelectorOpen(!accountsSelectorOpen)}
            >
              {selectedAccount.hasOwnProperty('title') &&
              selectedAccount.livemode === false
                ? `${selectedAccount.title} (Test Mode)`
                : selectedAccount.title}
              {accounts.length > 1 && (
                <FontAwesomeIcon
                  icon={['far', 'angle-down']}
                  className={`${
                    scrollHeight >= '112' ? 'text-grey-darker ' : 'text-black '
                  }ml-2`}
                />
              )}
            </button>
            {accountsSelectorOpen && (
              <div
                className="dropdown-content m-h-64 z-1000 overflow-y-auto overflow-x-hidden rounded"
                ref={accountSelectorRef}
              >
                <ul className="w-full list-reset py-12">
                  {currentUserSettings.donately_team_member && (
                    <li className="px-12">
                      <div>
                        <input
                          className="border border-grey-light rounded py-1 mx-2  w-2/3"
                          onChange={e => setSubdomain(e.target.value)}
                          onKeyDown={onKeyPress}
                          id="account-input"
                        />
                        <button
                          className="dntly-btn-secondary-search mr-2"
                          onClick={() =>
                            dispatch(fetchAccount(storeUser, subdomain))
                          }
                        >
                          Switch
                        </button>
                      </div>
                    </li>
                  )}
                  {accounts.map((account, i) => (
                    <li
                      className="hover:bg-grey-lighter px-12 p-3 select-account flex nowrap text-grey-darker hover:text-teal items-center cursor-pointer bg-white"
                      key={i}
                      onClick={() => changeAccount(account)}
                      value={account.unique_identifier}
                    >
                      <div
                        id="image-border"
                        className="border-pale-blue flex-none  bg-contain rounded-full h-12 w-12 flex items-center justify-center"
                      >
                        {account.images.logo.original ? (
                          <img
                            className="w-8"
                            src={account.images.logo.original}
                            alt=""
                          />
                        ) : (
                          <svg
                            className="h-4 fill-current mr-2 my-auto pl-3 inline-block w-8"
                            viewBox="0 0 24 18.7"
                          >
                            <path
                              d="M23.1,4.7l-3.8-3.8c-1.1-1.1-2.8-1.1-3.8,0L12,4.3L8.6,0.9c-1.1-1.1-2.8-1.1-3.8,0L0.9,4.7
                            c-1.1,1.1-1.1,2.8,0,3.8l9.2,9.2c1.1,1.1,2.8,1.1,3.8,0l2.9-2.9l0,0l6.2-6.2C24.2,7.5,24.2,5.8,23.1,4.7z M16.8,1.5
                            c0.3-0.3,0.8-0.3,1.1,0l0.7,0.7l-4.4,4.4L13,5.3L16.8,1.5z M22.5,7.2L18.7,11l-3.5-3.5l4.4-4.4l3,3C22.8,6.4,22.8,6.9,22.5,7.2z"
                            />
                          </svg>
                        )}
                      </div>
                      <div className="ml-3 flex-1">
                        {account.livemode === false
                          ? `${account.title} (test mode)`
                          : account.title}
                      </div>
                    </li>
                  ))}
                  <li
                    className="px-12 pt-6 select-account flex nowrap text-green-light hover:text-teal items-center cursor-pointer  "
                    onClick={() =>
                      history.push({
                        pathname: '/create_account',
                        state: { newAccount: true },
                      })
                    }
                  >
                    <div className="h-12 w-12 flex items-center justify-center">
                      <FontAwesomeIcon
                        className=""
                        icon={['far', 'plus']}
                        size="sm"
                      />
                    </div>
                    <div className="ml-1 flex-1">Create another account?</div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="navbar ml-auto my-auto hidden lg:flex">
        <HelperNavbarSection />
        <ProfileNavbarSection />
      </div>
      <TopNavMobile />
    </div>
  );
};

export default TopNav;
