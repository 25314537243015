import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

const ContactSupport = () => {
  const { selectedAccount } = useSelector(state => state.accountReducer);

  if (!selectedAccount) return null;

  return (
    <li className="border-t border-grey-light mt-4">
      <a
        className="cursor-pointer my-4"
        target="_blank"
        rel="noopener noreferrer"
        href={
          get(selectedAccount, 'billing.subscription_plan', 'Free') === 'Free'
            ? 'https://share.hsforms.com/1u4V8idbYQFukwjXvRabJOQby8td'
            : 'https://donately.com/contact'
        }
      >
        Contact Support
      </a>
    </li>
  );
};

export default ContactSupport;
