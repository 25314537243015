import React from 'react';
import { PropTypes } from 'prop-types';

function Button(props) {
  const {
    onClick,
    text,
    type,
    styledType,
    size,
    customClassName,
    textClassName,
    children,
    ...allOthersProps
  } = props;

  // Add here the buttons types that are available for small sizes
  const smallSizeAvailableButtons = ['danger'];
  // Add here the buttons types that are available for large sizes
  const largeSizeAvailableButtons = ['primary', 'secondary', 'light'];

  const getButtonClassNameType = btnType => {
    switch (btnType) {
      case 'primary':
        return 'dntly-btn-primary';
      case 'secondary':
        return 'dntly-btn-secondary';
      case 'danger':
        return 'dntly-btn-danger';
      case 'warning':
        return 'dntly-btn-warning';
      case 'warning-outlined':
        return 'dntly-btn-warning outlined';
      case 'danger-filled':
        return 'dntly-btn-danger filled';
      case 'danger-outlined':
        return 'dntly-btn-danger outlined';
      case 'light':
        return 'btn-light';
      case 'upgrade':
        return 'dntly-btn-upsell';
      case 'upgrade-outlined':
        return 'dntly-btn-upsell outlined';
      case 'upgrade-secondary':
        return 'dntly-btn-upsell-secondary';
      case 'primary-search':
        return 'dntly-btn-primary-search';
      case 'secondary-search':
        return 'dntly-btn-secondary-search';
      case 'none':
        return '';
      default:
        return 'dntly-btn-primary';
    }
  };

  const getButtonSize = (btnSize, btnType) => {
    switch (btnSize) {
      case 'default':
        return '';
      case 'small':
        return smallSizeAvailableButtons.includes(btnType) ? '-small' : '';
      case 'large':
        return largeSizeAvailableButtons.includes(btnType)
          ? ' dntly-btn-large'
          : '';
      default:
        return '';
    }
  };

  const buttonType = getButtonClassNameType(styledType);
  const buttonSize = getButtonSize(size, styledType);

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={`${buttonType}${buttonSize} ${customClassName}`}
      onClick={onClick}
      {...allOthersProps}
    >
      {children || <span className={textClassName}>{text}</span>}
    </button>
  );
}

Button.defaultProps = {
  text: 'Click Here',
  type: 'button',
  styledType: 'primary',
  size: 'default',
  customClassName: '',
  textClassName: '',
  children: null,
};

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  styledType: PropTypes.string,
  size: PropTypes.string,
  customClassName: PropTypes.string,
  textClassName: PropTypes.string,
  children: PropTypes.any,
};

export default Button;
