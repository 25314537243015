import { get } from 'lodash';
import { rebuildApiHeaders } from '../../library/functions';

export const INITIATE_FETCH_REPORTS = 'INITIATE_FETCH_REPORTS';
export function initiateFetchReports(reportType) {
  return {
    type: INITIATE_FETCH_REPORTS,
    reportType,
  };
}
export const INITIATE_CREATE_REPORT = 'INITIATE_CREATE_REPORT';
export function initiateCreateReport(reportType) {
  return {
    type: INITIATE_CREATE_REPORT,
    reportType,
  };
}
export const RECEIVE_REPORTS = 'RECEIVE_REPORTS';
export function receiveReports(items, key, reportType) {
  return {
    type: RECEIVE_REPORTS,
    items,
    key,
    reportType,
  };
}
export const PREPEND_REPORT = 'PREPEND_REPORT';
export function prependReport(report, reportType) {
  return {
    type: PREPEND_REPORT,
    report,
    reportType,
  };
}

export function fetchReportsFor(reportType, forceRefetch = false) {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        selectedAccount: { id: accountIdentifier },
        storeUser: headers,
      },
      reportsReducer: { reportsByType },
      myProfileReducer: {
        userSettings: { id: personId },
      },
    } = getState();
    const { key: reportsKey, items: previousReports } = get(
      reportsByType,
      reportType,
      {
        items: null,
        key: null,
        isFetching: false,
      }
    );
    if (personId) {
      await dispatch(initiateFetchReports(reportType));
      const generatedKey = `${personId}_${accountIdentifier}`;
      if (reportsKey !== generatedKey || forceRefetch) {
        const response = await fetch(
          `${process.env.DONATELY_API_V2_URL}/reports.json?account_id=${accountIdentifier}&section=${reportType}`,
          {
            method: 'GET',
            json: true,
            rejectUnauthorized: false,
            headers: rebuildApiHeaders(headers, '2022-12-15'),
          }
        );
        if (response.status === 200) {
          const json = await response.json();
          dispatch(receiveReports(json.data, generatedKey, reportType));
        }
      } else {
        dispatch(receiveReports(previousReports, generatedKey, reportType));
      }
    }
  };
}

export function createReport(name, reportType, values) {
  return async (dispatch, getState) => {
    const {
      accountReducer: {
        selectedAccount: { id: accountIdentifier },
        storeUser: headers,
      },
      myProfileReducer: {
        userSettings: { id: personId },
      },
    } = getState();
    if (personId) {
      await dispatch(initiateCreateReport(reportType));
      const response = await fetch(
        `${process.env.DONATELY_API_V2_URL}/reports.json`,
        {
          method: 'POST',
          json: true,
          rejectUnauthorized: false,
          headers: rebuildApiHeaders(headers, '2022-12-15'),
          body: JSON.stringify({
            account_id: accountIdentifier,
            name,
            section: reportType,
            filters: values,
          }),
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        dispatch(prependReport(json.data, reportType));
      }
    }
  };
}
